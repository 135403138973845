var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"px-6 mb-4"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"$magnify","outlined":"","dense":"","single-line":"","hide-details":"auto"},model:{value:(_vm.searchInputValue),callback:function ($$v) {_vm.searchInputValue=$$v},expression:"searchInputValue"}})],1)],1),_c('data-table',{ref:"table",attrs:{"headers":_vm.headers,"hide-default-footer":"","server-pagination":"","show-expand":"","single-expand":"","endpoint":"CompanyGroup/GetAll","search":_vm.searchInputValue},scopedSlots:_vm._u([{key:"item.companiesCount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.companiesCount)+" ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [(item.createdBy)?_c('div',[_vm._v(" "+_vm._s(item.createdBy.name)+" "+_vm._s(item.createdBy.surname)+" ")]):_vm._e()]}},{key:"item.dateOfCreation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfCreation))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openEditGroupDialog(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.edit")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openRemoveGroupDialog(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.remove")))])],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-6",attrs:{"colspan":headers.length}},[_c('h4',[_vm._v(_vm._s(_vm.$t("companies.detailsHeaders.title")))]),_c('v-data-table',{staticClass:"pointer",attrs:{"headers":_vm.detailsHeaders,"items":item.companies,"hide-default-footer":""},on:{"click:row":_vm.goToCompanyProfile}})],1)]}}],null,true)}),_c('RemoveGroupDialog',{attrs:{"group":_vm.groupToRemove},on:{"close":function($event){return _vm.closeRemoveGroupDialog()},"group-removed":function($event){return _vm.groupRemoved()}},model:{value:(_vm.removeGroupDialog),callback:function ($$v) {_vm.removeGroupDialog=$$v},expression:"removeGroupDialog"}}),_c('EditGroupDialog',{attrs:{"group":_vm.groupToEdit},on:{"close":function($event){return _vm.closeEditGroupDialog()},"group-edited":function($event){return _vm.groupEdited()}},model:{value:(_vm.editGroupDialog),callback:function ($$v) {_vm.editGroupDialog=$$v},expression:"editGroupDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }