var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"px-6 mb-4"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"$magnify","outlined":"","dense":"","single-line":"","hide-details":"auto"},model:{value:(_vm.searchInputValue),callback:function ($$v) {_vm.searchInputValue=$$v},expression:"searchInputValue"}})],1),_c('v-col',{attrs:{"cols":"2","offset":"6"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","block":""},on:{"click":function($event){return _vm.clearFilters()}}},[_vm._v(" "+_vm._s(_vm.$t('companies.clearFilters'))+" ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","block":"","depressed":""},on:{"click":function($event){return _vm.openFilterDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('companies.filter'))+" ")])],1)],1),_c('v-data-table',{attrs:{"items":_vm.companies,"loading":_vm.loading,"headers":_vm.headers,"hide-default-footer":"","page":_vm.dataTableOptions.page,"itemsPerPage":_vm.dataTableOptions.itemsPerPage},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.cooperationStatus",fn:function(ref){
var item = ref.item;
return [(_vm.$store.getters.isOwner || _vm.$store.getters.isManager)?_c('v-select',{attrs:{"items":_vm.companyCooperationStatuses,"item-text":"description","item-value":"id","dense":""},on:{"change":function($event){return _vm.setCooperationStatus(item.id, item.cooperationStatus)}},model:{value:(item.cooperationStatus),callback:function ($$v) {_vm.$set(item, "cooperationStatus", $$v)},expression:"item.cooperationStatus"}}):_c('div',[_vm._v(" "+_vm._s(_vm.companyCooperationStatuses.find(function (status) { return status.id === item.cooperationStatus; }).description)+" ")])]}},{key:"item.groups",fn:function(ref){
var item = ref.item;
return [(item.groups)?_c('div',[_vm._v(" "+_vm._s(_vm.getInLineGroups(item.groups))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.showProfile")))])],1),(item.groups)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openEditCompanyGroupDialog(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.edit")))])],1):_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openAddCompanyToGroupDialog(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("common.add")))])],1)],1)],1)]}},(_vm.pageCount > 1)?{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.dataTableOptions.page),callback:function ($$v) {_vm.$set(_vm.dataTableOptions, "page", $$v)},expression:"dataTableOptions.page"}})]},proxy:true}:null],null,true)}),_c('FilterDialog',{attrs:{"filters":_vm.filters},on:{"close":_vm.closeFilterDialog,"filter":_vm.filter},model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}}),_c('AddCompanyToGroupDialog',{attrs:{"company":_vm.companyToAdd},on:{"close":function($event){return _vm.closeAddCompanyToGroupDialog()},"company-added":function($event){return _vm.companyAddedToGroup()}},model:{value:(_vm.addCompanyToGroupDialog),callback:function ($$v) {_vm.addCompanyToGroupDialog=$$v},expression:"addCompanyToGroupDialog"}}),_c('EditCompanyGroupDialog',{attrs:{"company":_vm.companyToEditGroup},on:{"close":function($event){return _vm.closeEditCompanyGroupDialog()},"company-group-edited":function($event){return _vm.companyGroupEdited()}},model:{value:(_vm.editCompanyGroupDialog),callback:function ($$v) {_vm.editCompanyGroupDialog=$$v},expression:"editCompanyGroupDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }