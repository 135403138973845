<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="850px"
  >
    <v-card v-if="company.companyChanges">
      <v-card-title>
        <span
          class="text-h5"
        >
          {{ $t("companies.dialogs.dataChanges.title") }}: {{ company.companyName }}
        </span>
      </v-card-title>
      <v-card-subtitle>
        <p>
          {{ $t("companies.dialogs.dataChanges.dateOfRequest") }}:
          {{ company.companyChanges.dateOfRequest | dateFormat }}
        </p>
        <span>
          {{ $t("companies.dialogs.dataChanges.companyDisplayId") }}:
          {{ company.displayId }}
        </span>
      </v-card-subtitle>

      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="5">
                <p>{{ $t("companies.dialogs.dataChanges.subtitleUpdate") }}</p>
                <label for="vatNumber">
                  {{ $t("companies.dialogs.dataChanges.vatNumber") }}
                </label>
                <v-text-field
                  id="vatNumber"
                  :value="company.companyChanges.vatNumber"
                  outlined
                  dense
                  readonly
                />
                <label for="companyName">
                  {{ $t("companies.dialogs.dataChanges.companyName") }}
                </label>
                <v-text-field
                  id="companyName"
                  :value="company.companyChanges.companyName"
                  outlined
                  dense
                  readonly
                />
                <label for="street">
                  {{ $t("companies.dialogs.dataChanges.street") }}
                </label>
                <v-text-field
                  id="street"
                  :value="company.companyChanges.street"
                  outlined
                  dense
                  readonly
                />
                <label for="zipCode">
                  {{ $t("users.dialogs.profileChanges.zipCode") }}
                </label>
                <v-text-field
                  id="zipCode"
                  :value="company.companyChanges.zipCode"
                  outlined
                  dense
                  readonly
                />
                <label for="city">{{
                  $t("companies.dialogs.dataChanges.city")
                }}</label>
                <v-text-field
                  id="city"
                  :value="company.companyChanges.city"
                  outlined
                  dense
                  readonly
                />
                <label for="country">
                  {{ $t("companies.dialogs.dataChanges.country") }}
                </label>
                <!-- TODO: Kiedy będzie lista krajów trzeba ją tutaj zbindować -->
                <v-text-field
                  id="country"
                  :value="company.companyChanges.country"
                  outlined
                  dense
                  readonly
                />
              </v-col>

              <v-col
                cols="5"
                offset="2"
              >
                <p>{{ $t("companies.dialogs.dataChanges.subtitle") }}</p>
                <label for="vatNumber">
                  {{ $t("companies.dialogs.dataChanges.vatNumber") }}
                </label>
                <v-text-field
                  id="vatNumber"
                  :value="company.vatNumber"
                  outlined
                  dense
                  disabled
                />
                <label for="companyName">
                  {{ $t("companies.dialogs.dataChanges.companyName") }}
                </label>
                <v-text-field
                  id="companyName"
                  :value="company.companyName"
                  outlined
                  dense
                  disabled
                />
                <label for="street">
                  {{ $t("companies.dialogs.dataChanges.street") }}
                </label>
                <v-text-field
                  id="street"
                  :value="company.street"
                  outlined
                  dense
                  disabled
                />
                <label for="zipCode">
                  {{ $t("users.dialogs.profileChanges.zipCode") }}
                </label>
                <v-text-field
                  id="zipCode"
                  :value="company.zipCode"
                  outlined
                  dense
                  disabled
                />
                <label for="city">
                  {{ $t("users.dialogs.profileChanges.city") }}
                </label>
                <v-text-field
                  id="city"
                  :value="company.city"
                  outlined
                  dense
                  disabled
                />
                <label for="country">
                  {{ $t("companies.dialogs.dataChanges.country") }}
                </label>
                <!-- TODO: Kiedy będzie lista krajów trzeba ją tutaj zbindować -->
                <v-text-field
                  id="country"
                  :value="company.country"
                  outlined
                  dense
                  disabled
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex">
                <v-btn
                  text
                  @click="cancel"
                >
                  {{ $t("common.cancel") }}
                </v-btn>
                <v-spacer />
                <v-btn
                  color="secodary"
                  outlined
                  class="mr-2"
                  :disabled="loading"
                  @click="rejectChanges()"
                >
                  {{ $t("companies.dialogs.dataChanges.reject") }}
                </v-btn>
                <v-btn
                  color="primary"
                  :loading="loading"
                  :disabled="loading"
                  @click="approveChanges()"
                >
                  {{ $t("companies.dialogs.dataChanges.approve") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DataChanges',
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    rejectChanges () {
      this.$emit('rejectChanges')
    },
    approveChanges () {
      this.$emit('approveChanges')
    }
  }
}
</script>
