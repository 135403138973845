<template>
  <v-dialog v-model="value" width="600" persistent>
    <v-form v-model="valid" ref="addGroupForm">
      <v-card class="w-100 rounded-lg">
        <v-card-title>{{ $t('companies.groups.add') }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.name"
            :label="$t('companies.groups.enterName')"
            outlined
            dense
            counter
            class="mb-2"
            :rules="nameRules"
          >
          </v-text-field>

          <v-textarea
            v-model="form.description"
            :label="$t('companies.groups.enterDescription')"
            outlined
            counter
            class="mb-2"
            :rules="descriptionRules"
          >
          </v-textarea>

          <AutoComplete
            v-model="form.companies"
            :items="companies"
            :label="$t('companies.groups.addCompanies')"
            item-text="name"
            item-value="id"
            multiple
            outlined
            dense
            class="mb-2"
            :rules="requiredRules"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                class="mt-1"
                small
                color="primary"
              >
                <span>{{ item.name }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption mt-1"
              >
                  (+{{ form.companies.length -1 }} {{ $t('common.others')}})
              </span>
            </template>
          </AutoComplete>
          <v-card-actions class="d-flex justify-end px-0">
            <v-btn outlined @click="close()">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn outlined class="ml-auto" @click="saveAndAddNext()">
            {{ $t('companies.groups.saveAndAddNext') }}
          </v-btn>
          <v-btn color="primary" @click="save()" depressed>
            {{ $t('companies.groups.save') }}
          </v-btn>
        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Service from '@/services'
import AutoComplete from '@/components/forms/AutoComplete.vue'

export default {
  name: 'AddGroupDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  components: {
    AutoComplete
  },
  data () {
    return {
      form: {
        name: null,
        description: null,
        companies: []
      },
      valid: true,
      companies: [],
      nameRules: [
        (v) => !!v || this.$t('validation.fieldRequired'),
        (v) => (v && v.length <= 50) || this.$t('validation.groupNameMaximumLength')
      ],
      descriptionRules: [
        (v) => !!v || this.$t('validation.fieldRequired'),
        (v) => (v && v.length <= 250) || this.$t('validation.groupDescriptionMaximumLength')
      ],
      requiredRules: [
        (v) => (v && v.length > 0) || this.$t('validation.fieldRequired')
      ]
    }
  },
  async created () {
    await this.getCompanies()
  },
  methods: {
    async getCompanies () {
      try {
        const { data } = await Service.get(
          '/Company/GetCompanyNames'
        )
        this.companies = data
      } catch (e) {}
    },
    close () {
      this.$emit('close')
      this.$emit('group-added')
      this.$refs.addGroupForm.resetValidation()
      this.form = {}
    },
    async save () {
      this.$refs.addGroupForm.validate()

      if (!this.valid) {
        return
      }

      try {
        await Service.post(
          'CompanyGroup/CreateGroup',
          this.form)

        await this.$store.dispatch('setSuccessNotification',
          this.$t('common.confirmOperation')
        )
        this.$emit('group-added')
        this.form = {}
        this.$refs.addGroupForm.resetValidation()
      } catch (e) { }
    },
    async saveAndAddNext () {
      this.$refs.addGroupForm.validate()

      if (!this.valid) {
        return
      }

      try {
        await Service.post(
          'CompanyGroup/CreateGroup',
          this.form)

        await this.$store.dispatch('setSuccessNotification',
          this.$t('common.confirmOperation')
        )

        this.form = {}
        this.$refs.addGroupForm.resetValidation()
      } catch (e) { }
    }
  }
}
</script>

<style scoped>

</style>
