<template>
  <v-dialog v-model="value" width="600" persistent>
    <v-form v-model="valid" ref="editCompanyGroupForm">
      <v-card class="w-100 rounded-lg">
        <v-card-title>{{ $t('companies.editCompanyGroups') }}</v-card-title>
        <v-card-text>
          <v-select
            v-model="form.groups"
            :items="groups"
            :label="$t('companies.groups.title')"
            item-text="name"
            item-value="id"
            multiple
            outlined
            dense
            class="mb-2"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                class="mt-1"
                small
                color="primary"
              >
                <span>{{ item.name }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption mt-1"
              >
                  (+{{ form.groups.length -1 }} {{ $t('common.others')}})
              </span>
            </template>
          </v-select>

        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            outlined
            @click="close()"
          >
            {{ $t('common.cancel') }}
          </v-btn>

          <v-btn
            color="primary"
            @click="save()"
          >
            {{ $t('common.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Service from '@/services'
import { cloneObj } from '@/utils/helpers'

export default {
  name: 'EditCompanyGroupDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      groups: [],
      valid: true
    }
  },
  computed: {
    form: {
      get () {
        return cloneObj(this.company)
      },
      set () {}
    }
  },
  async created () {
    await this.getGroups()
  },
  methods: {
    async getGroups () {
      try {
        const { data } = await Service.get(
          '/Company/GetGroupsForSearch'
        )
        this.groups = data
      } catch (e) {}
    },
    close () {
      this.$emit('close')
      this.$refs.editCompanyGroupForm.resetValidation()
    },
    async save () {
      this.$refs.editCompanyGroupForm.validate()

      if (!this.valid) {
        return
      }

      try {
        const params = {
          companyId: this.company.id,
          groups: this.form.groups
        }

        await Service.put(
          '/CompanyGroup/UpdateCompanyGroups',
          params
        )

        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('common.confirmOperation')
        )
        this.$emit('company-group-edited')
      } catch (e) {}
    }
  }
}
</script>

<style scoped>

</style>
