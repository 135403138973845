<template>
  <div>
    <v-row class="px-6 mb-4">
      <v-col cols="2">
        <v-text-field v-model="searchInputValue" prepend-inner-icon="$magnify" outlined dense single-line hide-details="auto" />
      </v-col>
      <v-col cols="2" offset="6">
        <v-btn class="mr-2" outlined @click="clearFilters()" block>
          {{ $t('companies.clearFilters') }}
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" @click="openFilterDialog()" block depressed>
          {{ $t('companies.filter') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :items="companies"
      :loading="loading"
      :headers="headers"
      hide-default-footer
      :page="dataTableOptions.page"
      :itemsPerPage="dataTableOptions.itemsPerPage"
      @update:options="updateOptions"
    >
      <template v-slot:[`item.cooperationStatus`]="{ item }">
        <v-select
          v-if="$store.getters.isOwner || $store.getters.isManager"
          v-model="item.cooperationStatus"
          :items="companyCooperationStatuses"
          item-text="description"
          item-value="id"
          dense
          @change="setCooperationStatus(item.id, item.cooperationStatus)"
        ></v-select>
        <div v-else>
          {{ companyCooperationStatuses.find(status => status.id === item.cooperationStatus).description }}
        </div>
      </template>

      <template
        v-slot:[`item.groups`]="{ item }"
      >
        <div v-if="item.groups">
          {{ getInLineGroups(item.groups) }}
        </div>
      </template>

      <template
        v-slot:[`item.actions`]="{ item }"
      >
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="handleClick(item)">
              <v-list-item-title>{{
                  $t("common.showProfile")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.groups" link @click="openEditCompanyGroupDialog(item)">
              <v-list-item-title>{{
                  $t("common.edit")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-else link @click="openAddCompanyToGroupDialog(item)">
              <v-list-item-title>{{
                  $t("common.add")
                }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer v-if="pageCount > 1">
        <v-pagination v-model="dataTableOptions.page" :length="pageCount"> </v-pagination>
      </template>
    </v-data-table>

    <FilterDialog
      v-model="filterDialog"
      :filters="filters"
      @close="closeFilterDialog"
      @filter="filter"
    />

    <AddCompanyToGroupDialog
      v-model="addCompanyToGroupDialog"
      :company="companyToAdd"
      @close="closeAddCompanyToGroupDialog()"
      @company-added="companyAddedToGroup()"
    />

    <EditCompanyGroupDialog
      v-model="editCompanyGroupDialog"
      :company="companyToEditGroup"
      @close="closeEditCompanyGroupDialog()"
      @company-group-edited="companyGroupEdited()"
    />
  </div>
</template>

<script>
import Service from '@/services'
import { mapGetters } from 'vuex'
import FilterDialog from '@/views/Companies/Market/dialogs/FilterDialog'
import AddCompanyToGroupDialog from '@/views/Companies/Market/dialogs/AddCompanyToGroupDialog'
import EditCompanyGroupDialog from '@/views/Companies/Market/dialogs/EditCompanyGroupDialog'

export default {
  name: 'CompaniesTable',
  components: {
    EditCompanyGroupDialog,
    AddCompanyToGroupDialog,
    FilterDialog
  },
  data () {
    return {
      pageCount: 1,
      companies: [],
      loading: true,
      searchInputValue: '',
      dataTableOptions: {
        page: 1,
        itemsPerPage: 15
      },
      filterDialog: false,
      filters: {
        countries: [],
        groups: [],
        city: [],
        cooperationStatus: null
      },
      companyToAdd: {},
      addCompanyToGroupDialog: false,
      sortProperties: [],
      editCompanyGroupDialog: false,
      companyToEditGroup: {},
      getDataTimeOut: null,
      headers: [
        {
          text: this.$t('companies.companyName'),
          value: 'name'
        },
        {
          text: this.$t('companies.localisation'),
          value: 'localisation'
        },
        {
          text: this.$t('companies.vatNumber'),
          value: 'vatNumber'
        },
        {
          text: this.$t('companies.companyId'),
          value: 'displayId'
        },
        {
          text: this.$t('companies.cooperationStatus'),
          value: 'cooperationStatus',
          width: 175
        },
        {
          text: this.$t('companies.group'),
          value: 'groups'
        },
        {
          text: '',
          align: 'end',
          width: '75',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    searchInputValue () {
      this.search()
    },
    'dataTableOptions.page' () {
      this.getCompanies()
    }
  },
  computed: {
    ...mapGetters({
      enums: 'enums'
    }),
    companyCooperationStatuses () {
      const { companyCooperationStatus } = this.enums
      return companyCooperationStatus
    }
  },
  async created () {
    await this.getCompanies()
  },
  methods: {
    async getCompanies () {
      try {
        const params = {
          page: this.dataTableOptions.page,
          pageSize: this.dataTableOptions.itemsPerPage,
          search: this.searchInputValue,
          countries: JSON.stringify(this.filters.countries),
          city: this.filters.city,
          groups: JSON.stringify(this.filters.groups),
          cooperationStatus: this.filters.cooperationStatus,
          sortableProperties: JSON.stringify(this.sortableProperties)
        }

        const { data } = await Service.get(
          '/Company/SearchCompanies', params
        )
        this.companies = data.results
        this.pageCount = Math.ceil(data.count / this.dataTableOptions.itemsPerPage)
        this.loading = false
      } catch (e) {}
    },
    async setCooperationStatus (companyId, targetStatus) {
      try {
        await Service.post(
          '/Company/SetCompanyCooperationStatus', {
            companyId: companyId,
            cooperationStatus: targetStatus
          }
        )

        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companies.cooperationStatusChange')
        )
      } catch (e) {}
    },
    getInLineGroups (groups) {
      if (!groups) {
        return
      }

      return groups.map(g => g.name).join(', ')
    },
    async search () {
      this.dataTableOptions.page = 1

      const timeout = 250
      clearTimeout(this.getDataTimeout)
      this.getDataTimeout = setTimeout(await this.getCompanies, timeout)
    },
    closeFilterDialog () {
      this.filterDialog = false
    },
    async openFilterDialog () {
      this.filterDialog = true
    },
    async clearFilters () {
      this.filters = {
        countries: [],
        groups: [],
        city: [],
        cooperationStatus: null
      }
      await this.getCompanies()
    },
    async filter (filters) {
      this.filters = filters
      this.dataTableOptions.page = 1
      await this.getCompanies()
      this.filterDialog = false
    },
    updateOptions (options) {
      this.sortProperties = options.sortBy.map((element, index) => {
        return {
          propertyName: element,
          priority: index,
          type: options.sortDesc[index] ? 1 : 0
        }
      })
    },
    openAddCompanyToGroupDialog (item) {
      this.companyToAdd = item
      this.addCompanyToGroupDialog = true
    },
    closeAddCompanyToGroupDialog () {
      this.addCompanyToGroupDialog = false
    },
    async companyAddedToGroup () {
      await this.getCompanies()
      this.addCompanyToGroupDialog = false
    },
    openEditCompanyGroupDialog (item) {
      this.companyToEditGroup = item
      this.editCompanyGroupDialog = true
    },
    closeEditCompanyGroupDialog () {
      this.editCompanyGroupDialog = false
    },
    async companyGroupEdited () {
      await this.getCompanies()
      this.editCompanyGroupDialog = false
    },
    handleClick (value) {
      const routeData = this.$router.resolve({ name: 'CompanyDetailsMarket', params: { id: value.id } })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
